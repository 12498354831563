<template>
  <v-tour name="vuexyTour" :steps="tourSteps" :options="options"></v-tour>
</template>

<script>
export default {
  props: {
    steps: Array // Passing the steps as a prop
  },
  computed: {
    tourSteps() {
      return this.steps.map((step) => {
        step.offset = -500 // Fine-tune offset for better positioning
        return step
      })
    }
  },
  data() {
    return {
      options: {
        labels: {
          buttonSkip: 'Batal',
          buttonPrevious: 'Kembali',
          buttonNext: 'Lanjut',
          buttonStop: 'Selesai'
        }
      }
    }
  }
}
</script>

<style lang="scss">
  .v-step {
    background-color: white !important;
    color: black !important;

    .v-step__header {
      background-color: #7367f0 !important;
      color: white !important;
    }

    .v-step__button {
      border-color: #7367f0 !important;
      color: #7367f0 !important;
    }
  }
</style>