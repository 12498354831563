<template>
  <div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            @input="onSearch"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <!-- :pagination-options="paginationOptions" -->
    <vue-good-table
      :rtl="direction"
      :select-options="selectOptions"
      :columns="fields"
      :rows="data"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
        total: total,
        mode: 'remote',
        nextLabel: 'Next',
        prevLabel: 'Previous'
      }"
      @on-page-change="onPageChange"
    >
      <!-- @on-per-page-change="onPerPageChange" -->
      <template v-slot:table-row="props">
        <span v-if="props.column.field === 'badge'">
          <b-badge pill variant="light-primary"
            >{{ props.row.badge }}
            <b-badge pill variant="primary">{{
              props.row.cat
            }}</b-badge></b-badge
          >
          <div class="divider">
            <div class="divider-text">
              <feather-icon
                icon="ShieldIcon"
                class="align-items-center text-danger"
              />
              <span @click="copyText(props.row.ktp)">
                <strong> KTP : {{ props.row.ktp }}</strong>
                <!-- :src="`https://api.salpalaran.com/avatar.png`" -->
                <b-avatar
                  v-if="props.row.raw_data[`${props.row.raw_data.model}`][
                    `file_storage_ktp_${props.row.raw_data.model}`
                  ].length != 0"
                  button
                  class="ml-1"
                  size="52"
                  :src="`${BACKEND_URL}/dir_visitor_ktp/${
                    props.row.raw_data[`${props.row.raw_data.model}`][
                      `file_storage_ktp_${props.row.raw_data.model}`
                    ][0].unique_name
                  }`"
                  :text="`asd`"
                  :variant="`primary`"
                  @click="
                    showKTP(
                      `${BACKEND_URL}/dir_visitor_ktp/${
                        props.row.raw_data[`${props.row.raw_data.model}`][
                          `file_storage_ktp_${props.row.raw_data.model}`
                        ][0].unique_name
                      }`,

                      props.row.full_name,
                      props.row.badge,
                      props.row.id
                    )
                  "
                />
                <b-avatar
                  v-else
                  button
                  class="ml-1"
                  size="52"
                  :src="`error`"
                  :text="``"
                  :variant="`primary`"
                  @click="
                    showKTP(
                      `error`,
                      props.row.full_name,
                      props.row.badge,
                      props.row.id
                    )
                  "
                />
              </span>
            </div>
          </div>
          <!-- default -->
          <b-badge
            id="tour-qrcode"
            class="cursor-pointer"
            pill
            variant="primary"
            @click="
              showCustomImage(
                props.row.qrcode_link,
                props.row.full_name,
                props.row.badge,
                props.row.id
              )
            "
          >
            <feather-icon icon="EyeIcon" class="mr-25" />

            <span>QRCode</span>
          </b-badge>

          <b-badge
            id="tour-qrcode-status"
            class="ml-2"
            pill
            :variant="
              props.row.status.toLowerCase() === 'expired'
                ? `danger`
                : props.row.status.toLowerCase() === 'available'
                ? `success`
                : `warning`
            "
            style="width: 100px"
          >
            {{ props.row.status }}
          </b-badge>

          <b-badge
            id="tour-send-wa"
            class="ml-2 cursor-pointer"
            pill
            variant="success"
            @click="
              sendWhatsapp(
                props.row.qrcode_link,
                props.row.nomor_whatsapp,
                props.row.full_name,
                props.row.cat
              )
            "
          >
            <feather-icon icon="PhoneCallIcon" class="mr-25" />

            <span v-text="`Send WA`"></span>
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'is_active'">
          <b-form-checkbox
            :id="`tour-qrcode-allowscan-${props.row.id}`"
            :checked="props.row.is_active"
            class="custom-control-primary mr-0"
            name="check-button"
            switch
            inline
            @change="onUpdateIsActive(props.row, 'is_active')"
          >
            <span class="switch-icon-left">
              <feather-icon icon="ZapIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="ZapOffIcon" />
            </span>
          </b-form-checkbox>
          allow scan
          <div class="divider">
            <div class="divider-text">-</div>
          </div>
          <b-form-checkbox
            :id="`tour-qrcode-autorenew-${props.row.id}`"
            :checked="props.row.sys_auto_renew"
            class="custom-control-primary mr-0"
            :disabled="
              props.row.status.toLowerCase() === 'expired' ? true : false
            "
            name="check-button"
            switch
            inline
            @change="onUpdateIsActive(props.row, 'sys_auto_renew')"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CloudLightningIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="CloudOffIcon" />
            </span>
          </b-form-checkbox>
          auto renew
        </span>

        <span v-else-if="props.column.field === 'full_name'">
          <feather-icon icon="UserIcon" class="align-items-center" />
          {{ props.row.full_name }}
          <div class="divider">
            <div class="divider-text">
              <small>{{ props.row.perusahaan }}</small>
            </div>
          </div>
          <feather-icon icon="PhoneCallIcon" class="align-items-center" />
          {{ props.row.nomor_whatsapp }}
        </span>

        <span v-else-if="props.column.field === 'status'">
          <b-badge variant="success" style="width: 100px">
            {{ props.row.status }}
          </b-badge>
          <div class="divider">
            <div class="divider-text">-</div>
          </div>
          <!-- <b-badge variant="danger" style="width: 100px"> Expired </b-badge> -->
          <small>{{ props.row.perusahaan }}</small>
        </span>

        <span v-else-if="props.column.field === 'last_in'">
          <b-badge variant="success" style="width: 70px">
            <feather-icon icon="ClockIcon" class="align-items-center" /> IN
          </b-badge>
          {{ props.row.last_in }}
          <div class="divider">
            <div class="divider-text">-</div>
          </div>
          <b-badge variant="primary" style="width: 70px">
            <feather-icon icon="ClockIcon" class="align-items-center" /> OUT
          </b-badge>
          {{ props.row.last_out }}
        </span>

        <!-- <span v-else-if="props.column.field === customFields.actionField">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item @click="$emit('edit', props.row)">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="$emit('delete', props.row)">
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </span> -->
        <span v-else-if="props.column.field === 'id'">
          {{ props.row.id }}
          <b-dropdown
            v-if="
              props.row.last_in !== 'Not Available' &&
              props.row.last_out === 'Not Available'
            "
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="24"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              @click="emergencyClockOUT(props.row)"
              variant="danger"
            >
              <feather-icon icon="AlertTriangleIcon" class="mr-50" />
              <strong>
                <span>Emergency Clock OUT [ID {{ props.row.id }}]</span>
              </strong>
            </b-dropdown-item>
          </b-dropdown>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template v-slot:pagination-bottom="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"
              >Showing {{ (currentPage - 1) * perPage + 1 }} to</span
            >
            <b-form-select
              v-model="perPage"
              :options="pageOptions"
              class="mx-1"
              @input="(value) => perPageChanged(value)"
            />
            <span class="text-nowrap">of {{ total }} entries</span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="onPageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BSpinner,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormCheckbox
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { saveAs } from 'file-saver'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'YoGoodTable',
  components: {
    BSpinner,
    BFormCheckbox,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem
  },
  directives: {
    Ripple
  },
  props: {
    fields: Array, // Table columns
    data: Array, // Table data
    total: Number, // Total number of items from the API response
    perPage: Number, // Items per page (set default if not passed)
    currentPage: Number, // Current page number (set default if not passed)

    selectOptions: {
      type: Object,
      default: () => ({
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true
      })
    }
  },
  data() {
    return {
      searchTerm: '',
      sendWhatsappLoading: false,
      BACKEND_URL: process.env.VUE_APP_BACKEND_URL,

      pageOptions: [5, 10, 15, 20], // Options for items per page dropdown
      pageLength: this.perPage || 10 // Default per page
    }
  },
  mounted() {},
  computed: {
    statusVariant() {
      const statusColor = {
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info'
      }
      return (status) => statusColor[status]
    },
    direction() {
      return store.state.appConfig.isRTL
    }
  },
  methods: {
    rowStyleClassFn(row) {
      return row.is_active === true ? 'green' : 'red'
    },
    // Emit event when page changes
    onPageChange(newPage) {
      this.$emit('page-changed', newPage)
    },

    // Emit event when per page count changes
    perPageChanged(newPerPage) {
      this.$emit('per-page-changed', newPerPage)
    },
    async emergencyClockOUT(row) {
      const { id, qrcode_link, sys_auto_renew, model } = row.raw_data
      const { from_company, badge_id, full_name } = row.raw_data[`${model}`]

      let payload = {
        visitor_qrcode_id: id,
        full_name: full_name,
        badge_id: badge_id,
        qrcode_link: qrcode_link,
        sys_auto_renew: sys_auto_renew,

        /* for notifications */
        token: this.$store.state.visitor.tokenNotification,
        model: model,
        from_company: from_company
      }

      await useJwt.http
        .post('visitor/scanning/action', payload)
        .then(async (result) => {
          console.log(result.data)
          const { action_success, action, action_message } = result.data

          if (action_success === true) {
            if (action === 'clock_in') {
              //
            } else {
              this.$swal({
                title: 'Clock OUT Area',
                html: `${full_name} Berhasil Keluar Area PT SAL`,
                timer: 3000,
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
            }
          } else {
            this.$swal({
              title: 'OOPS',
              html: `${full_name} ${action_message}`,
              timer: 3000,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          }
        })
    },
    async sendWhatsapp(qrcode_link, phone, full_name, cat) {
      const imageURL = `${this.BACKEND_URL}/dir_visitor_qrcode/${qrcode_link}`
      console.log('qr:', imageURL)

      this.$swal({
        title: `Whatsapp ${phone}`,
        text: 'Sinergi, Taat, Aman dan Responsif',
        // eslint-disable-next-line global-require
        imageUrl: imageURL,
        // imageWidth: 402,
        // imageHeight: 472,
        imageAlt: 'QRCode',
        confirmButtonText: 'Sent Whatsapp',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1'
        },

        buttonsStyling: false
      }).then(async (result) => {
        console.log('result', result)
        if (result.value) {
          await useJwt.http.post('visitor_send_whatsapp', {
            phone: phone,
            qrcode_link: qrcode_link,
            full_name: full_name,
            cat
          })
        }
      })
    },
    async showKTP(fileName, fullName, badge, id) {
      const imageURL = `${fileName}`
      console.log('qr:', imageURL)
      const fileNames = `${fullName}_${id}_${badge}.png`
      console.log(fileNames)

      this.$swal({
        title: 'KTP',
        html: 'Identitas Pengunjung<br/>Document yang diupload oleh Pengunjung',
        // eslint-disable-next-line global-require
        imageUrl: imageURL,
        // imageWidth: 402,
        // imageHeight: 472,
        imageAlt: 'KTP',
        confirmButtonText: 'Download',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1'
        },

        buttonsStyling: false
      }).then(async (result) => {
        if (result.value) {
          saveAs(imageURL, fileNames) //
        }
      })
    },
    async showCustomImage(fileName, fullName, badge, id) {
      const imageURL = `${this.BACKEND_URL}/dir_visitor_qrcode/${fileName}`
      console.log('qr:', imageURL)
      const fileNames = `${fullName}_${id}_${badge}.png`
      console.log(fileNames)

      this.$swal({
        title: 'Star',
        text: 'Sinergi, Taat, Aman dan Responsif',
        // eslint-disable-next-line global-require
        imageUrl: imageURL,
        // imageWidth: 402,
        // imageHeight: 472,
        imageAlt: 'QRCode',
        confirmButtonText: 'Download',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },

        buttonsStyling: false
      }).then(async (result) => {
        if (result.value) {
          saveAs(imageURL, fileNames) //
        }
      })
    },
    copyText(string) {
      this.$copyText(string).then(
        () => {
          this.$alert({
            title: `Text copied : ${string}`,
            variant: 'primary',
            icon: 'CheckCircleIcon'
          })
        },
        (e) => {
          this.$alert({
            title: `Can not copy!`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
        }
      )
    },
    async onSearch() {
      try {
        console.log('Emit the event to parent component [onSearch]')
        this.$emit('on-search', this.searchTerm)
        // // Make API call with search term
        // const response = await axios.get('/api/search', {
        //   params: { query: this.searchTerm }
        // })
        // // Update the table data with the server response
        // this.data = response.data
      } catch (error) {
        console.error('Error fetching search results:', error)
      }
    },
    onUpdateIsActive(row, state) {
      // console.log('row', row.id)
      console.log('Emit the event to parent component', row)
      this.$emit('update-is-active', row, state)
    }
  }
}
</script>

<style scoped>
/* Add scoped styles if needed */
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
